import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "types";
import { AccountStore, AccountUser } from "./types";

const initialState: AccountStore = {
  accountUser: null,
  isLoading: true,
};

const accountSlice = createSlice({
  name: "accountStore",
  initialState,
  reducers: {
    updateAccountUser: (state, action: PayloadAction<{ accountUser: Nullable<AccountUser> }>) => {
      state.accountUser = action.payload.accountUser;
      state.isLoading = false;
    },
    updateIsLoadingAccountUser: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
  },
});

export const { updateAccountUser, updateIsLoadingAccountUser } = accountSlice.actions;

export default accountSlice.reducer;
