import React from "react";
import CustomButton from "../Viewer/CustomButton";
import classNames from "classnames";

interface Props {
  text: string;
  icon?: React.ReactElement;
  extraClassNames?: string;
}

const ActionButton = ({ text, icon, extraClassNames }: Props) => {
  return (
    <div className="my-5">
      <CustomButton onClick={() => {}} className={classNames("w-44 px-0 text-sm", extraClassNames)}>
        <div className="flex items-center justify-between">
          <div className="ml-2">{icon}</div>
          <div className="w-32">{text}</div>
        </div>
      </CustomButton>
    </div>
  );
};

export default ActionButton;
