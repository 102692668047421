import React from "react";
import moment from "moment";

import Checkbox from "./Checkbox";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
import { Person } from "../Person/types";
import { Nullable } from "../../types";

interface Props {
  person: Person;
  setChecked: (personUuid: string) => void;
  checkedPersonUuid: Nullable<string>;
}

const SearchRow = ({ person, setChecked, checkedPersonUuid }: Props) => {
  return (
    <tr key={person.uuid} className="h-10 border-b text-xs cursor-default bg-white hover:bg-cotton">
      <td className="pl-4">
        <Checkbox checked={person.uuid === checkedPersonUuid} onClick={setChecked} />
      </td>
      <td className="pl-4">
        <div className="flex px-2 text-pacificBlue underline">
          <Flags.WS title="WS" className="w-5" />
          <div className="px-1">
            {person.firstName} {person.lastName}
          </div>
        </div>
      </td>
      <td className="pl-4">
        <div className="flex text-slate">
          <div className="pr-1">{moment(person.dob, "DD MMMM, YYYY").format("DD MMMM, YYYY")}</div>
        </div>
      </td>
    </tr>
  );
};

export default SearchRow;
