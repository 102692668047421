import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { Nullable } from "../../types";
import { Person } from "../Person/types";
import PersonalDetails from "../NewRelationship/PersonalDetails";
import CustomDatePicker from "../../utils/CustomDatePicker";
import FieldInput from "../NewRelationship/FieldInput";
import Spacer from "../NewRelationship/Spacer";
import CustomButton from "../Viewer/CustomButton";
import DragAndDropBox, { DocumentFile } from "../NewRelationship/DragAndDropBox";
import BackArrow from "../Assets/BackArrow";

interface Props {
  person: Nullable<Person>;
  title: string;
  saveClick: (values: Person) => void;
  saveButtonText: string;
  backClick: () => void;
  editing?: boolean;
  loading?: boolean;
}

const personDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter a first name"),
  lastName: Yup.string().required("Please enter a last name"),
  dob: Yup.number().optional().required("Please enter a date of birth"),
  city: Yup.string().optional(),
  citizenship: Yup.string().optional(),
  otherNames: Yup.string().optional(),
  idDocumentNumber: Yup.string().optional(),
  idDocumentType: Yup.string().optional(),
  streetAddress: Yup.string().required(),
  cityAddress: Yup.string().required(),
  postalCodeAddress: Yup.string().optional(),
  countryAddress: Yup.string().required(),
  mobile: Yup.string().test(function (value) {
    const { email } = this.parent;
    if (!email) {
      console.log("Mobile val", value);
      if (!value) {
        return this.createError({
          message: `Please enter either a mobile or email`,
          path: "mobile",
        });
      } else {
        return true;
      }
    }
    return true;
  }),
  email: Yup.string()
    .email()
    .test(function (value) {
      const { mobile } = this.parent;
      if (!mobile) {
        console.log("Email val", value);
        if (!value) {
          return this.createError({
            message: `Please enter either a mobile or email`,
            path: "email",
          });
        } else {
          return true;
        }
      }
      return true;
    }),
  IRDNumber: Yup.string().optional(),
  samoanIRDNumber: Yup.number().optional(),
  employer: Yup.string().optional(),
});

const EntityPage = ({
  person,
  title,
  saveClick,
  backClick,
  saveButtonText,
  loading = false,
  editing = false,
}: Props) => {
  const initialPayslipDoc = {
    fileName: person?.payslipFileName ? person?.payslipFileName : null,
    baseUrl: person?.payslipFile ? person?.payslipFile : null,
  };
  const initialIdDocument = {
    fileName: person?.idDocumentFileName ? person?.idDocumentFileName : null,
    baseUrl: person?.idDocumentFile ? person?.idDocumentFile : null,
  };
  const [payslipDocument, setPayslipDocument] = React.useState<DocumentFile>(initialPayslipDoc);
  const [idDocumentFile, setIdDocumentFile] = React.useState<DocumentFile>(initialIdDocument);

  return (
    <div>
      <div>
        <h1 className="text-h1 font-bold">{title}</h1>
      </div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          firstName: person?.firstName ? person.firstName : "",
          middleName: person?.middleName ? person.middleName : "",
          lastName: person?.lastName ? person.lastName : "",
          dob: person?.dob ? person.dob : "",
          city: person?.city ? person.city : "",
          citizenship: person?.citizenship ? person.citizenship : "",
          otherNames: person?.otherNames ? person.otherNames : "",
          idDocumentNumber: person?.idDocumentNumber ? person.idDocumentNumber : "",
          idDocumentType: person?.idDocumentType ? person.idDocumentType : "",
          streetAddress: person?.streetAddress ? person.streetAddress : "",
          cityAddress: person?.cityAddress ? person?.cityAddress : "",
          postalCodeAddress: person?.postalCodeAddress ? person?.postalCodeAddress : "",
          countryAddress: person?.countryAddress ? person.countryAddress : "",
          mobile: person?.mobile ? person.mobile : "",
          email: person?.email ? person.email : "",
          IRDNumber: person?.IRDNumber ? person.IRDNumber : "",
          samoanIRDNumber: person?.samoanIRDNumber ? person.samoanIRDNumber : "",
          employer: person?.employer ? person.employer : "",
        }}
        validationSchema={personDetailsSchema}
        onSubmit={(values: any, { setSubmitting }) => {
          saveClick({
            ...values,
            idDocumentFile: idDocumentFile?.baseUrl,
            idDocumentFileName: idDocumentFile?.fileName,
            payslipFile: payslipDocument?.baseUrl,
            payslipFileName: payslipDocument?.fileName,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="text-slate">
                <h2 className="text-h2 font-bold mb-4">Personal details</h2>
                <PersonalDetails
                  disabled={editing}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />

                <div className="h-17.5">
                  <div className="text-xs mb-1 text-slate">{"Date of birth*"}</div>
                  <CustomDatePicker
                    onChange={(value: Date) => setFieldValue("dob", moment(value).valueOf())}
                    initialDate={values["dob"]}
                  />
                  <div className="text-xxs text-warning">{errors["dob"]}</div>
                </div>

                <FieldInput
                  disabled={editing}
                  name={"city"}
                  title={"City of birth"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <FieldInput
                  name={"citizenship"}
                  title={"Citizenship"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes="w-100"
                />
                <Spacer />
                <h2 className="text-h2 mb-5 font-bold">ID Verification</h2>
                <div className="flex flex-row">
                  <FieldInput
                    name={"idDocumentNumber"}
                    title={"ID number"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"idDocumentType"}
                    title={"Type"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <DragAndDropBox setBase64File={setIdDocumentFile} file={idDocumentFile} />
                <Spacer />
                <h2 className="text-h2 mb-5 font-bold">Contact information</h2>
                <FieldInput
                  name={"streetAddress"}
                  title={"Street Address*"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-56 mr-8"}
                />
                <FieldInput
                  name={"cityAddress"}
                  title={"City*"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-56 mr-8"}
                />
                <FieldInput
                  name={"postalCodeAddress"}
                  title={"Post Code"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-56 mr-8"}
                />
                <FieldInput
                  name={"countryAddress"}
                  title={"Country*"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  classes={"w-56 mr-8"}
                />
                <FieldInput
                  name={"mobile"}
                  title={"Mobile phone number*"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <FieldInput
                  disabled={editing}
                  name={"email"}
                  title={"Email address*"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <Spacer />
                <h2 className="text-h2 mb-5 font-bold">Tax information</h2>
                <div className="flex">
                  <FieldInput
                    name={"IRDNumber"}
                    title={"IRD number"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"samoanIRDNumber"}
                    title={"Samoan IRD number"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <FieldInput
                  name={"employer"}
                  title={"Who do you work for (employer)?"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                />
                <div className="text-xs mb-1">Let us know your income please. Upload a payslip scan.</div>
                <div className="h-4" />
                <DragAndDropBox setBase64File={setPayslipDocument} file={payslipDocument} />
                <div className="h-8" />
                <div className="w-full bg-sky h-24 mb-10 flex justify-between items-center">
                  <CustomButton
                    type="button"
                    theme="secondary"
                    disabled={loading}
                    className={"ml-8"}
                    onClick={backClick}
                  >
                    <div className="flex items-center">
                      <div className="mr-2">
                        <BackArrow disabled={loading} />
                      </div>
                      <div>Back</div>
                    </div>
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    disabled={loading}
                    onClick={() => {}}
                    className={"mr-8"}
                    loading={loading}
                  >
                    <div>{saveButtonText}</div>
                  </CustomButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EntityPage;
