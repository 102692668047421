import React from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import FieldInput from "./FieldInput";
import Spacer from "./Spacer";
import CustomButton from "../Viewer/CustomButton";
import BackArrow from "../Assets/BackArrow";
import PersonalDetails from "./PersonalDetails";
import { Person } from "../Person/types";
import {
  useGetActiveReceiver,
  useGetActiveRelationship,
  useUpdateActiveRelationship,
  useUpdateActiveStep,
} from "../Relationship/relationshipHooks";
import { useUpdateEntity } from "../Person/personHooks";
import CustomDatePicker from "../../utils/CustomDatePicker";

const ReceiverDetailsSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter a first name"),
  middleName: Yup.string().optional(),
  lastName: Yup.string().required("Please enter a last name"),
  dob: Yup.number().optional().required("Please enter a date of birth"),
  city: Yup.string().optional(),
  citizenship: Yup.string().optional(),
  streetAddress: Yup.string().required(),
  cityAddress: Yup.string().required(),
  postalCodeAddress: Yup.string().optional(),
  countryAddress: Yup.string().required(),
  nearestBranch: Yup.string().optional(),
  mobile: Yup.string().test(function (value) {
    const { email } = this.parent;
    if (!email) {
      if (!value) {
        return this.createError({
          message: `Please enter either a mobile or email`,
          path: "mobile",
        });
      } else {
        return true;
      }
    }
    return true;
  }),
  email: Yup.string()
    .email()
    .test(function (value) {
      const { mobile } = this.parent;
      if (!mobile) {
        if (!value) {
          return this.createError({
            message: `Please enter either a mobile or email`,
            path: "email",
          });
        } else {
          return true;
        }
      }
      return true;
    }),
});

interface Props extends RouteComponentProps {
  relationshipUuid?: string;
}

const ReceiverDetailsPage: React.FC<Props> = ({ relationshipUuid }) => {
  const { updateEntity } = useUpdateEntity();
  const updateActiveStep = useUpdateActiveStep();
  const updateActiveRelationship = useUpdateActiveRelationship();

  const receiver = useGetActiveReceiver();

  React.useEffect(() => {
    if (relationshipUuid) {
      updateActiveRelationship(relationshipUuid);
    }
  }, [updateActiveRelationship, relationshipUuid]);

  const activeRelationShip = useGetActiveRelationship();

  const locationState: any = useLocation().state;

  React.useEffect(() => {
    if (!activeRelationShip) {
      navigate("/relationships");
    }
  }, [activeRelationShip]);

  const backClick = () => {
    if (activeRelationShip) {
      navigate(`/relationships/${activeRelationShip.relationshipUuid}/sender-details`);
    }
  };
  const saveClick = (values: Person) => {
    if (receiver && activeRelationShip) {
      updateEntity(receiver.uuid, values);
      navigate(`/relationships/${activeRelationShip.relationshipUuid}/mti`, { state: { saveClicked: true } });
    }
  };

  React.useEffect(() => {
    updateActiveStep("receiverDetails");
  }, [updateActiveStep]);

  React.useEffect(() => {
    if (locationState?.saveClicked) {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }, [locationState]);

  return (
    <div className="flex-1">
      <div>
        <h1 className="text-h1 mb-8 font-bold">New transfer • Receiver details</h1>
        <div>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              firstName: receiver?.firstName ? receiver.firstName : "",
              middleName: receiver?.middleName ? receiver.middleName : "",
              lastName: receiver?.lastName ? receiver.lastName : "",
              dob: receiver?.dob ? receiver.dob : "",
              city: receiver?.city ? receiver.city : "",
              citizenship: receiver?.citizenship ? receiver.citizenship : "",
              streetAddress: receiver?.streetAddress ? receiver.streetAddress : "",
              cityAddress: receiver?.cityAddress ? receiver?.cityAddress : "",
              postalCodeAddress: receiver?.postalCodeAddress ? receiver?.postalCodeAddress : "",
              countryAddress: receiver?.countryAddress ? receiver.countryAddress : "",
              nearestBranch: receiver?.nearestBranch ? receiver.nearestBranch : "",
              mobile: receiver?.mobile ? receiver.mobile : "",
              email: receiver?.email ? receiver.email : "",
            }}
            validationSchema={ReceiverDetailsSchema}
            onSubmit={(values: any, { setSubmitting }) => {
              saveClick(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <div className="text-slate">
                  <h2 className="text-h2 font-bold mb-4">Receiver personal details</h2>
                  <PersonalDetails
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />

                  <div className="h-17.5">
                    <div className="text-xs mb-1 text-slate">{"Date of birth*"}</div>
                    <CustomDatePicker
                      onChange={(value: Date) => setFieldValue("dob", moment(value).valueOf())}
                      initialDate={values["dob"]}
                    />
                    <div className="text-xxs text-warning">{errors["dob"]}</div>
                  </div>

                  <FieldInput
                    name={"city"}
                    title={"City"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                  <FieldInput
                    name={"citizenship"}
                    title={"Citizenship"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes="w-100"
                  />
                  <Spacer />
                  <h2 className="text-h2 font-bold mb-4">Receiver contact information</h2>
                  <FieldInput
                    name={"streetAddress"}
                    title={"Street Address*"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"cityAddress"}
                    title={"City*"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"postalCodeAddress"}
                    title={"Post Code"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"countryAddress"}
                    title={"Country*"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"nearestBranch"}
                    title={"What is your nearest branch?"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                    classes={"w-56 mr-8"}
                  />
                  <FieldInput
                    name={"mobile"}
                    title={"Mobile phone number*"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                  <FieldInput
                    name={"email"}
                    title={"Email address*"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                  <Spacer />

                  <div className="w-full bg-sky h-24 mb-10 flex justify-between items-center">
                    <CustomButton
                      type="button"
                      theme="secondary"
                      disabled={isSubmitting}
                      className={"ml-8"}
                      onClick={backClick}
                    >
                      <div className="flex items-center">
                        <div className="mr-2">
                          <BackArrow />
                        </div>
                        <div>Back</div>
                      </div>
                    </CustomButton>
                    <CustomButton type="submit" disabled={isSubmitting} className={"mr-8"}>
                      <div>Save & next</div>
                    </CustomButton>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ReceiverDetailsPage;
