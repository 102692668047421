import React from "react";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
import { useUpdateActiveRelationship } from "../Relationship/relationshipHooks";
import Checkbox from "../Search/Checkbox";
import moment from "moment";
import { Person } from "../Person/types";
import { Nullable } from "../../types";
import { navigate } from "@reach/router";

interface Props {
  person: Person;
  setChecked: (personUuid: string) => void;
  checkedPersonUuid: Nullable<string>;
}

const EntityRow = ({ person, setChecked, checkedPersonUuid }: Props) => {
  const updateActiveRelationship = useUpdateActiveRelationship();

  const handleClick = () => {
    navigate(`/entities/${person.uuid}`);
  };

  return (
    <tr
      onClick={handleClick}
      key={person.uuid}
      className="h-10 border-b text-xs cursor-pointer bg-white hover:bg-cotton"
    >
      <td className="pl-4">
        <Checkbox checked={person.uuid === checkedPersonUuid} onClick={setChecked} />
      </td>
      <td className="pl-4">
        <div className="flex px-2 text-pacificBlue underline">
          <div className="px-1">
            {person.firstName} {person.lastName}
          </div>
        </div>
      </td>
      <td className="pl-4">
        <div className="flex text-slate">
          <div className="pr-1">{moment(person.dob).format("DD MMMM, YYYY")}</div>
        </div>
      </td>
    </tr>
  );
};

export default EntityRow;
