import React from "react";
import { navigate } from "@reach/router";
import { orderBy } from "lodash";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addRelationships,
  createRelationshipRequest,
  updateActiveRelationship,
  updateActiveStep,
  updateRelationshipRequest,
} from "./relationshipSlice";
import { addEntities, updatePerson } from "../Person/personSlice";
import { v4 as uuidv4 } from "uuid";
import { getActiveRelationship, getActiveRelationshipUuid, getRelationships } from "./relationshipSelectors";
import { NewRelationshipPage } from "./types";
import { replaceWhitespace, stepToPathnameMap } from "../utils";
import { getPersonByUuid } from "../Person/personSelector";
import { Nullable } from "../../types";
import { usePerson } from "../Person/personHooks";
import { StatusFilters } from "../Dashboard/types";
import { DateFilters } from "../Entities/types";
import { Api } from "../API/API";
import { shapeEntity } from "../Entities/EntityHooks";
import axios, { CancelToken } from "axios";
import { BankNotSupportedMessage } from "../constants";
import { getAccountUser, getIsLoadingAccountUser } from "../Account/accountSelectors";

export const useUpdateActiveStep = () => {
  const dispatch = useAppDispatch();

  const activeRelationshipUuid = useAppSelector(getActiveRelationshipUuid);

  return React.useCallback(
    (activeStep: NewRelationshipPage) => {
      if (!activeRelationshipUuid) {
        return;
      }
      dispatch(updateActiveStep({ relationshipUuid: activeRelationshipUuid, activeStep }));
    },
    [dispatch, activeRelationshipUuid],
  );
};

export const useCreateNewRelationship = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (senderUuid: string, receiverUuid: string) => {
      dispatch(updatePerson({ uuid: receiverUuid }));
      dispatch(updatePerson({ uuid: senderUuid }));
      const relationshipUuid = uuidv4();
      dispatch(createRelationshipRequest({ relationshipUuid, senderUuid, receiverUuid, status: "draft" }));
      return relationshipUuid;
    },
    [dispatch],
  );
};

export const useCreateNewRelationshipAndSetActive = () => {
  const dispatch = useAppDispatch();
  const createNewRelationship = useCreateNewRelationship();
  return React.useCallback(
    async (senderUuid: Nullable<string>, receiverUuid: Nullable<string>) => {
      const relationshipUuid = await createNewRelationship(
        senderUuid ? senderUuid : uuidv4(),
        receiverUuid ? receiverUuid : uuidv4(),
      );

      dispatch(updateActiveRelationship(relationshipUuid));

      navigate(`/relationships/${relationshipUuid}/receiver-details`, { state: { saveClicked: true } });
    },
    [createNewRelationship],
  );
};

export const useUpdateActiveRelationship = () => {
  const dispatch = useAppDispatch();

  return React.useCallback(
    (relationshipUuid: Nullable<string>) => {
      dispatch(updateActiveRelationship(relationshipUuid));
    },
    [dispatch],
  );
};

export const useGetActiveRelationship = () => {
  return useAppSelector(getActiveRelationship);
};

export const useGetActiveSender = () => {
  const activeRelationShip = useGetActiveRelationship();

  return useAppSelector((state) => getPersonByUuid(state, activeRelationShip?.senderUuid));
};

export const useGetSenderByUuid = (uuid?: string) => {
  return useAppSelector((state) => getPersonByUuid(state, uuid));
};

export const useGetActiveReceiver = () => {
  const activeRelationShip = useGetActiveRelationship();

  const receiverUuid = activeRelationShip?.receiverUuid;

  return useAppSelector((state) => getPersonByUuid(state, receiverUuid));
};

export const useGoToStep = () => {
  const dispatch = useAppDispatch();
  const relationships = useAppSelector(getRelationships);

  return React.useCallback(
    async (relationshipUuid: string) => {
      const relationship = relationships[relationshipUuid];

      const activeStep = relationship?.activePage;

      if (!activeStep) {
        return;
      }

      const activeUrl = stepToPathnameMap(relationshipUuid)[activeStep];
      await dispatch(updateActiveRelationship(relationshipUuid));
      navigate(activeUrl);
    },
    [relationships, dispatch],
  );
};

export const useUpdateTransferDetails = () => {
  const dispatch = useAppDispatch();

  const activeRelationshipUuid = useAppSelector(getActiveRelationshipUuid);

  return React.useCallback(
    (values: any) => {
      if (!activeRelationshipUuid) {
        return;
      }

      dispatch(updateRelationshipRequest({ ...values, relationshipUuid: activeRelationshipUuid, ...values }));
    },
    [activeRelationshipUuid, dispatch],
  );
};

export const useRelationships = () => {
  const { relationships } = useAppSelector((state) => state.relationshipStore);
  return relationships;
};

export const useSortedRelationships = () => {
  const relationships = useRelationships();

  const getPerson = usePerson();

  return React.useCallback(
    (
      direction: "asc" | "desc",
      searchValue: Nullable<string>,
      statusFilter?: StatusFilters,
      dateFilter?: DateFilters,
    ) => {
      const shapedRelationShips = Object.values(relationships).map((relationship) => {
        const sender = getPerson(relationship.senderUuid);
        const receiver = getPerson(relationship.receiverUuid);
        return {
          relationshipUuid: relationship.relationshipUuid,
          senderFirstName: sender?.firstName?.toLowerCase(),
          senderLastName: sender?.lastName?.toLowerCase(),
          receiverFirstName: receiver?.firstName?.toLowerCase(),
          receiverLastName: receiver?.lastName?.toLowerCase(),
          status: relationship.status,
          validFrom: relationship.validFrom,
          validTo: relationship.validTo,
        };
      });

      const orderedShapedRelationships = orderBy(
        shapedRelationShips,
        ["senderLastName", "senderFirstName"],
        [direction, direction],
      );

      const searchedRelationships = !searchValue
        ? orderedShapedRelationships
        : orderedShapedRelationships.filter((relationship) => {
            const senderFullName = `${relationship.senderFirstName} ${relationship.senderLastName}`;
            const receiverFullName = `${relationship.receiverFirstName} ${relationship.receiverLastName}`;
            return (
              receiverFullName.toLowerCase().includes(searchValue.toLowerCase()) ||
              senderFullName.toLowerCase().includes(searchValue.toLowerCase())
            );
          });

      const statusFilteredRelationships =
        !statusFilter || statusFilter === "all"
          ? searchedRelationships
          : searchedRelationships.filter((relationship) => relationship.status === statusFilter);

      const dateFilteredRelationships = !dateFilter
        ? statusFilteredRelationships
        : statusFilteredRelationships.filter((relationship) => {
            const now = moment();
            const diff = now.diff(moment(relationship.validFrom), "days");
            return diff <= dateFilter;
          });

      return dateFilteredRelationships.reduce((accum, relationship) => {
        return {
          ...accum,
          [relationship.relationshipUuid]: relationships[relationship.relationshipUuid],
        };
      }, {});
    },
    [relationships, getPerson],
  );
};

export const useGetRelationships = () => {
  const [loading, setLoading] = React.useState(false);
  const [cancelled, setCancelled] = React.useState(false);
  const dispatch = useAppDispatch();

  const accountUser = useAppSelector(getAccountUser);
  const isLoadingAccountUser = useAppSelector(getIsLoadingAccountUser);

  const getRelationships = async (cancelToken: CancelToken) => {
    if (isLoadingAccountUser) {
      return;
    }
    if (!accountUser?.bankName) {
      alert(BankNotSupportedMessage);
      return;
    }
    setLoading(true);
    const searchFields = {
      bankName: accountUser.bankName,
    };
    const relationships = await Api.getRelationships({ cancelToken }, searchFields);

    if (cancelled || !relationships) {
      return;
    }

    const shapedRelationships = relationships.reduce((accum, relationship) => {
      const senderUuid = relationship.sender.uuid;
      const receiverUuid = relationship.receiver.uuid;
      const uuid = relationship.uuid;
      return {
        ...accum,
        [uuid]: {
          uuid,
          validFrom: moment(relationship.validFrom).valueOf(),
          validTo: moment(relationship.validTo).valueOf(),
          relationshipUuid: uuid,
          receiverUuid: receiverUuid,
          senderUuid: senderUuid,
          status: relationship.status ? relationship.status.toLowerCase() : "processing",
          activePage: "senderDetails",
          amount: relationship.amount,
          frequency: relationship.frequency,
          reason: relationship.reason,
          currencyCode: relationship.currencyCode,
        },
      };
    }, {});

    const shapedEntities = relationships.reduce((accum, relationship) => {
      const senderUuid = relationship.sender.uuid;
      const receiverUuid = relationship.receiver.uuid;

      return {
        ...accum,
        [senderUuid]: shapeEntity(relationship.sender, senderUuid),
        [receiverUuid]: shapeEntity(relationship.receiver, receiverUuid),
      };
    }, {});
    dispatch(addEntities(shapedEntities));
    dispatch(addRelationships(shapedRelationships));
    setLoading(false);
  };

  React.useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getRelationships(source.token);
    return () => {
      setCancelled(true);
      source.cancel();
    };
  }, [dispatch, setLoading, accountUser]);

  return {
    loading,
  };
};
