import React from "react";
import { FormikValues } from "formik";
import classNames from "classnames";

interface Props {
  title: string;
  name: string;
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FormEvent<HTMLInputElement>) => void;
  values: FormikValues;
  errors: any;
  touched: any;
  classes?: string;
  placeholder?: string;
  prefix?: React.ReactElement;
  disabled?: boolean;
}

const FieldInput = ({
  title,
  name,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  classes = "w-56",
  placeholder,
  prefix,
  disabled = false,
}: Props) => {
  return (
    <div className="h-17.5">
      <div className="text-xs mb-1 text-slate">{title}</div>
      <span
        className={classNames(
          "flex items-center bg-white hover:bg-snow border rounded h-8 text-slate border-stone text-sm",
          classes,
          { "pl-1": prefix },
        )}
      >
        {prefix}
        <input
          disabled={disabled}
          placeholder={placeholder}
          type={name}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          className={classNames(
            "focus:outline-none border-0 outline-none align-middle h-full w-full rounded",
            { "pl-1": !prefix, "bg-cloud text-jumbo hover:bg-cloud": disabled, "bg-white hover:bg-snow": !disabled },
          )}
        />
      </span>
      <div className="text-xxs text-warning">{errors[name]}</div>
    </div>
  );
};

export default FieldInput;
