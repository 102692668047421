import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enNZ from "date-fns/locale/en-NZ";
import { Nullable } from "../types";

registerLocale("enNZ", enNZ);

interface Props {
  onChange: (value: Date) => void;
  initialDate: Nullable<Date>;
}

const CustomDatePicker = ({ onChange, initialDate }: Props) => {
  const [startDate, setStartDate] = React.useState<Nullable<Date>>(initialDate);

  const handleChange = (date: Date) => {
    setStartDate(date);
    onChange(date);
  };
  return (
    <DatePicker
      className="border rounded border-stone h-8 text-slate text-sm focus:outline-none"
      placeholderText="dd/mm/yyyy"
      dateFormat="dd/MM/yyyy"
      locale="enNZ"
      selected={startDate}
      onChange={(date: Date) => handleChange(date)}
    />
  );
};
export default CustomDatePicker;
