import React from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import CurrencyInput from "react-currency-input-field";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomButton from "../Viewer/CustomButton";
import BackArrow from "../Assets/BackArrow";
import SelectInput from "./SelectInput";
import { useGetActiveRelationship, useUpdateActiveStep } from "../Relationship/relationshipHooks";
import { Nullable } from "../../types";
import { useCreateRelationship } from "./NewRelationshipHooks";

const MoneyTransferInformationSchema = Yup.object().shape({
  reason: Yup.string().required("Please enter a reason for this transfer"),
  amount: Yup.number().min(0.01, "Please enter an amount").required("Please enter an amount"),
  frequency: Yup.string().required("Please select from the drop down").nullable(),
  currencyCode: Yup.string().required("Please select a currency type"),
});

export interface MoneyTransferInformationValues {
  reason: string;
  amount: number;
  frequency: Nullable<string>;
  currencyCode: string;
}

const MoneyTransferInformationPage: React.FC<RouteComponentProps> = () => {
  const activeRelationShip = useGetActiveRelationship();
  const updateActiveStep = useUpdateActiveStep();

  const { createRelationship, loading } = useCreateRelationship();

  const backClick = () => {
    if (activeRelationShip) {
      navigate(`/relationships/${activeRelationShip.relationshipUuid}/receiver-details`);
    }
  };

  React.useEffect(() => {
    if (!activeRelationShip) {
      navigate("/relationships");
    }
  }, [activeRelationShip]);

  const locationState: any = useLocation().state;

  React.useEffect(() => {
    updateActiveStep("transferDetails");
  }, [updateActiveStep]);

  React.useEffect(() => {
    if (locationState?.saveClicked) {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }, [locationState]);

  const isNotDraft = activeRelationShip?.status !== "draft";

  return (
    <div className="flex-1 pr-6 lg:pr-0">
      <div>
        <h1 className="text-h1 mb-8 font-bold">New transfer • Transfer details</h1>
        <div>
          <h2 className="text-h2 font-bold mb-4">Money transfer information</h2>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              reason: activeRelationShip?.reason ? activeRelationShip.reason : "",
              amount: activeRelationShip?.amount ? activeRelationShip.amount : 0,
              frequency: activeRelationShip?.frequency ? activeRelationShip.frequency : null,
              currencyCode: activeRelationShip?.currencyCode ? activeRelationShip.currencyCode : "NZD",
            }}
            validationSchema={MoneyTransferInformationSchema}
            onSubmit={(values, { setSubmitting }) => {
              createRelationship(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              /* and other goodies */
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="text-slate flex flex-col flex-1">
                    <div className="text-xs mb-1 text-slate">Who is it going to? What is it for?*</div>
                    <textarea
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue("reason", e.target.value)}
                      className="h-20 text-sm border p-2 rounded text-slate border-stone"
                      placeholder="Reason for transfers ongoing"
                      name={"reason"}
                      defaultValue={activeRelationShip?.reason}
                    />
                    <div className="text-xs text-warning mt-2">{errors.reason}</div>
                    <div className="h-5" />
                    <div className="flex flex-col md:flex-row">
                      <div className="w-64">
                        <div className="text-xs text-slate">
                          <div className="mb-1">How much do you want to send?*</div>
                          <div className="flex">
                            <div className="mr-2">
                              <SelectInput
                                width={80}
                                onChange={(value: { value: string }) => {
                                  setFieldValue("currencyCode", value.value);
                                }}
                                selectedValue={{ value: values["currencyCode"], label: values["currencyCode"] }}
                                options={[
                                  { value: "NZD", label: "NZD" },
                                  { value: "AUD", label: "AUD" },
                                  { value: "USD", label: "USD" },
                                  { value: "WST", label: "WST" },
                                ]}
                              />
                            </div>
                            <CurrencyInput
                              decimalScale={2}
                              style={{ outline: "none" }}
                              value={values["amount"]}
                              decimalsLimit={2}
                              defaultValue={undefined}
                              onValueChange={(value: { value: string } | string | undefined) => {
                                setFieldValue("amount", value);
                              }}
                              className={
                                "text-left h-8 text-sm bg-white hover:bg-snow focus:outline-none border border-stone outline-none w-32 rounded"
                              }
                            />
                          </div>
                          <div className="text-xxs text-warning mt-2">{errors.amount}</div>
                        </div>
                      </div>
                      <div className="w-56">
                        <div className="text-xs mb-1 text-slate mt-4 md:mt-0">How frequently do you plan to send?*</div>
                        <SelectInput
                          selectedValue={{ value: values["frequency"], label: values["frequency"] }}
                          onChange={(value: { value: string }) => setFieldValue("frequency", value.value)}
                          options={[
                            { value: "Day", label: "Daily" },
                            { value: "Week", label: "Weekly" },
                            { value: "Fortnight", label: "Fortnightly" },
                            { value: "Quarter", label: "Quarterly" },
                            { value: "Month", label: "Monthly" },
                            { value: "Year", label: "Yearly" },
                          ]}
                        />
                        <div className="text-xxs text-warning mt-2">{errors.frequency}</div>
                      </div>
                    </div>
                    <div className="h-8" />
                    <div className="bg-sky h-24 mb-10 flex justify-between items-center">
                      <CustomButton
                        type="button"
                        theme="secondary"
                        className={"ml-8"}
                        onClick={backClick}
                        disabled={loading}
                      >
                        <div className="flex items-center">
                          <div className="mr-2">
                            <BackArrow disabled={loading} />
                          </div>
                          <div>Back</div>
                        </div>
                      </CustomButton>
                      <CustomButton type="submit" disabled={loading || isNotDraft} className={"mr-8"} loading={loading}>
                        <div>Submit</div>
                      </CustomButton>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MoneyTransferInformationPage;
